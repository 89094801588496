<template>
  <b-container>

    <h2 class="mt-2 font-weight-bolder">
      選擇設計師
    </h2>

    <!-- user suggestion  -->
    <b-card
      v-for="(personnel, index) in fetchData"
      :key="`p-${index}`"
      no-body
      class="mb-1"
    >
      <div class="px-1 py-75 d-flex justify-content-start align-items-center">
        <b-avatar
          :src="personnel.avatar"
          class="mr-1"
          size="40"
        />
        <div class="user-page-info">
          <h5 class="mb-0 font-weight-bolder">
            {{ personnel.name }}
          </h5>
          <small class="text-muted">{{ personnel.mutualFriend }}</small>
        </div>
        <b-button
          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
          variant="gradient-dark"
          class="btn-icon ml-auto"
          :to="{ name: 'apps-reserve-v2-page', params: { slug: $route.params.slug, code: personnel.code } }"
        >
          選擇顧問
        </b-button>
      </div>
    </b-card>
    <!--/ user suggestion  -->
  </b-container>
</template>

<script>
import {
  BContainer, BCard, BAvatar, BButton,
} from 'bootstrap-vue'
import { ref, onUnmounted } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import Ripple from 'vue-ripple-directive'
import store from '@/store'

import dataStoreModule from './reserveStoreModule'

export default {
  components: {
    BContainer,
    BCard,
    BAvatar,
    BButton,
  },
  directives: {
    Ripple,
  },
  setup(_, { root }) {
    const APP_STORE_MODULE_NAME = 'app-reserve-list'

    // Register module
    if (!store.hasModule(APP_STORE_MODULE_NAME)) store.registerModule(APP_STORE_MODULE_NAME, dataStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(APP_STORE_MODULE_NAME)) store.unregisterModule(APP_STORE_MODULE_NAME)
    })

    // Use Func
    const fetchData = ref([])
    const fetchAll = () => {
      store.dispatch(`${APP_STORE_MODULE_NAME}/fetchPersonnels`, {
        slug: root.$route.params.slug,
      })
        .then(response => {
          fetchData.value = response.data
        })
    }

    fetchAll()

    return {
      fetchData,

      // filter
      avatarText,
    }
  },
}
</script>
